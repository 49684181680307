<template>
  <div class="LoginItem">
    <ul>
      <li><img src="~@/assets/askFor/AskForSearch.png"></li>
      <li>登录</li>
      <li>
        <img src="~@/assets/askFor/AskForNavigation.png">
        <div class="LoginNav">
          <dl>
            <dd>
              <div class="LoginNavTitle">我的课程 ></div>
              <div class="LoginNavFont">访问您的课程并与同行互动</div>
            </dd>
            <dd>
              <div class="LoginNavTitle">我的账户 ></div>
              <div class="LoginNavFont">管理您的账户、应用 程序和付款</div>
            </dd>
          </dl>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "LoginItem",
};
</script>

<style lang="scss" scoped>
.LoginItem {
  position: absolute;
  right: 20px;
  top: 42px;
  font-size: 20px;
  font-weight: 400;
  color: #000000;
  line-height: 24px;
  ul {
    li {
      float: left;
      margin-left: 30px;
      cursor: pointer;
      position: relative;
      &:hover{
        .LoginNav{
          display: block;
        }
      }
      .LoginNav{
        position: absolute;
        right: 0;
        width: 242px;
        background: #FFFFFF;
        border: 1px solid #D4D4D4;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        
        border-radius: 6px;
        padding: 10px 25px;
        display: none;
        dd{
          padding: 10px 0;
        }
        .LoginNavTitle{
          color:#173D7C;
          line-height: 32px;
        }
        .LoginNavFont{
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          color: #191917;
          
        }
      }
    }
  }
}
</style>