<template>
  <div class="EduHome">
    <AskForHeader />
    <Banner />
    <main class="page-container">
      <div class="AskForMian">
        <div class="AskForFromTable">
          <table width="100%">
            <tr>
              <td colspan="2">
                <ul>
                  <li><label><input name="radio" type="radio">我对组织的企业培训或学术解决方案感兴趣</label></li>
                  <li><label><input name="radio" type="radio">我对作为个人学习感兴趣</label></li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <div class="FontTitle">名字</div>
                <div class="FontInput">
                  <input v-model="AskForFrom.name" type="text" placeholder="请填写您的名字">
                </div>
              </td>
              <td>
                <div class="FontTitle">姓</div>
                <div class="FontInput">
                  <input v-model="AskForFrom.surname" type="text" placeholder="请填写姓">
                </div>
              </td>
              </tr>
              <tr>
                <td colspan="2">
                  <div class="FontTitle">电子邮箱</div>
                  <div class="FontInput">
                    <input v-model="AskForFrom.eMail" type="text" placeholder="请填写登录邮箱">
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <div class="FontTitle">问题</div>
                  <div class="FontTextarea">
                    <textarea rows="10" v-model="AskForFrom.mobile" placeholder="请填写问题"></textarea>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        <button @click="AskForBut" type="button" class="AskForButton">提交</button>
      </div>
    </main>
    <footer class="LoginFooter">
      <div class="FooterCenter">
        <ul>
          <li>首页华路</li>
          <li>•</li>
          <li>国际名校</li>
          <li>•</li>
          <li>资讯中心</li>
          <li>•</li>
          <li>华路校友会</li>
          <li>•</li>
          <li>华路商学院简介</li>
          <li>•</li>
          <li>我要约课</li>
        </ul>
        <div class="FooterRight">
          <p>COPYRIGHT © 2009-2020 华路数字文化 版权所有</p>
          <p>www.cufehl.com  粤ICP备14094585号-1</p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import AskForHeader from "@/components/AskForHeader";
import Banner from "./components/Banner";
export default {
  name: "AskFor",
  data() {
    return {
      AskForFrom:{}
    };
  },
  components: {
    AskForHeader,
    Banner
  },
  created() {},
  methods: {
    AskForBut(){ //提交索取
      console.log(this.AskForFrom)
    }
  }
};
</script>

<style lang="scss" scoped>
.page-container {
  flex: 1;
  padding: 0px 40px 42px;
  .AskForMian{
    width: 1200px;
    margin: 0 auto;
    color: #333333;
    .AskForFromTable{
      margin: 0 -15px;
      border: 0;
      padding-bottom: 20px;
      td{
        padding: 0 15px;
        padding-bottom: 30px;
        box-sizing: border-box;
        width: 50%;
        .FontTitle{
          line-height: 40px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          
        }
        .FontInput{
          height: 45px;
          background: #FFFFFF;
          border: 1px solid #989898;
          
          border-radius: 3px;
          box-sizing:content-box;
          font-size: 0;
          input{
            width: 100%;
            box-sizing: border-box;
            border: 0;
            padding: 0 15px;
            height: 45px;
            font-size: 16px;
            border: 0;
            background: transparent;
          }
        }
        .FontTextarea{
          background: #FFFFFF;
          border: 1px solid #989898;
          
          border-radius: 3px;
          box-sizing:content-box;
          font-size: 0;
          textarea{
            width: 100%;
            box-sizing: border-box;
            border: 0;
            padding: 7px 15px;
            font-size: 16px;
            border: 0;
            background: transparent;
            line-height: 24px;
            font-family: 'Microsoft YaHei';
          }
        }
        ul{
          li{
            padding: 10px 0;
            line-height: 24px;
          }
        }
      }
    }
    .AskForButton{
      background: #173D7C;
      border-radius: 10px;
      cursor: pointer;
      border:0;
      font-size: 16px;
      color:#ffffff;
      width: 149px;
      height: 50px;
      
      &:hover{
        background: #244f96;
      }
    }
  }
}
.EduHome {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  footer {
    padding: 24px 40px 40px;
    font-size: 16px;
    color: $color-text-6;
    background-color: $color-background-5;
  }
}
.LoginFooter{
  border-top:1px solid #BFBFBF;
  .FooterCenter{
    width: 1200px;
    margin: 0 auto;
    padding: 20px 0;
    ul{
      float: left;
      li{
        float: left;
        margin-right: 20px;
      }
    }
    .FooterRight{
      float: right;
      text-align: right;
      p{
        padding: 0;
        margin: 0;
        line-height: 24px;
      }
    }
  }
}
</style>