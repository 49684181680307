<template>
  <div class="banner">
    <div class="bannerCenter">
      <div>
        <span>索取更多信息</span>
      </div>
      <div class="mt5">
        <span>Ask for more information</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "banner",
  components: {},
  data() {
    return {
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.banner {
  height: 170px;
  background: #FFD727;
  min-width: 1200px;
  .bannerCenter{
    margin: 0 auto;
    max-width: 1200px;
    padding-top: 40px;
    div{
      span{
        background: #211E1D;
        display: inline-block;
        color:#ffffff;
        line-height: 44px;
        padding: 0 10px;
        font-size: 32px;
        font-weight: 400;
      }
      &.mt5{
        margin-top: 5px;
        span{
          font-size: 36px;
        }
      }
    }
  }
}
</style>